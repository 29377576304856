<style scope lang="scss">

</style>

<template>
  <span>
    <el-date-picker class="c-datetime mrgb5" :placeholder="showEndTime ? '开始时间' : '请选择时间'" v-if="!isOnlyEndTime" v-model="urgentTime[0]" default-time="00:00:00" 
    :picker-options="pickerOptionsStart" type="datetime" size="small" :format="format" @change="getStartTime"></el-date-picker>
    <span v-if="showEndTime && !isOnlyEndTime"> 至 </span> 
    <el-date-picker class="c-datetime mrgb5" :placeholder="!isOnlyEndTime ? '结束时间' : '请选择时间'" v-if="showEndTime || isOnlyEndTime" v-model="urgentTime[1]" default-time="23:59:59" 
    :picker-options="pickerOptionsEnd" type="datetime" size="small" :format="format" @change="getEndTime"></el-date-picker>
  </span>
</template>

<script>
import moment from 'moment'
export default {
  name: "DateTime",
  props: {
    showEndTime: {
      default: true,
      type: Boolean
    },
    isOnlyEndTime: {
      default: false,
      type: Boolean
    },
    format: {
      default: "",
      type: String
    },
    defaultTime: {
      default: () => {
        return []
      }
    }
  },
  watch: {
    urgentTime(v) {
      this.commitPicker()
    },
    defaultTime(v) {
      this.urgentTime = [v[0] || null, v[1] || null]
      this.commitPicker()
    }
  },
  mounted() {
    this.commitPicker()
  }, 
  data() {
    return {
      urgentTime: [this.defaultTime[0] || null, this.defaultTime[1] || null],
      pickerOptionsStart: {
        disabledDate: (time) => {
          if (this.urgentTime[1] && this.showEndTime) {
            return time.getTime() > new Date(this.urgentTime[1]).getTime()
          }
        }
      },
      pickerOptionsEnd: {
        disabledDate: time => {
          if (this.urgentTime[0] && this.showEndTime) {
            return time.getTime() <= new Date(this.urgentTime[0]).getTime() - 3600 * 1000 * 24
          }
        }
      }
    }
  },
  methods: {
    getStartTime(val) {
      if (this.urgentTime[1] && val && this.showEndTime) {
        if (new Date(val).getTime() > new Date(this.urgentTime[1]).getTime()) {
          this.alert("开始时间不能大于结束时间!").then(() => {
            this.$set(this.urgentTime, 0, null)
          })
        }
      }
    },
    getEndTime(val) {
      if (this.urgentTime[0] && val && this.showEndTime) {
        if (new Date(val).getTime() < new Date(this.urgentTime[0]).getTime()) {
          this.alert("结束时间不能小于开始时间!").then(() => {
            this.$set(this.urgentTime, 1, null)
          })
        }
      }
    },
    commitPicker() {
      // 回传选中的值
      let tranceTime = [null, null]
      if (moment(this.urgentTime[0]).isValid()) {
        tranceTime[0] = moment(this.urgentTime[0]).format('YYYY-MM-DD HH:mm:ss')
      }
      if (moment(this.urgentTime[1]).isValid()) {
        tranceTime[1] = moment(this.urgentTime[1]).format('YYYY-MM-DD HH:mm:ss')
      }
      this.$emit("commitPicker", tranceTime)
    }
  }
}
</script>
